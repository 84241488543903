<template>
  <footer class="py-10 bg-black sm:pt-16 lg:pt-24">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-12">
        <div>
          <p class="text-xl font-bold text-white">GymBlaze</p>

          <ul class="mt-8 space-y-4">
            <li>
              <NuxtLink
                to="/personal-trainers"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
              >
                Personal Trainers
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/#services"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
              >
                Layanan
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/#faq"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
              >
                FAQ
              </NuxtLink>
            </li>
            <li>
              <NuxtLink
                to="/rules"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
              >
                Peraturan
              </NuxtLink>
            </li>
          </ul>
          <ul class="mt-8 space-y-4">
            <li>
              <NuxtLink
                to="/book"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
              >
                Pesan Sekarang
              </NuxtLink>
            </li>

            <li>
              <NuxtLink
                to="/about"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
              >
                Tentang Kami
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div class="lg:text-right">
          <p class="text-lg font-semibold text-white">Hubungi Kami</p>

          <ul class="mt-8 space-y-4">
            <li>
              <span class="text-gray-200"
                >Jl. Akses UI No.32B, Tugu, Kec. Cimanggis<br />Kota Depok, Jawa
                Barat, Indonesia 16451</span
              >
            </li>
            <li>
              <span class="text-gray-200" title="email">
                <Icon name="fa6-solid:envelope" class="pr-1" />
              </span>
              <a
                href="mailto:info@gymblaze.id"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white font-bold"
              >
                info@gymblaze.id
              </a>
            </li>
            <li>
              <span class="text-gray-200" title="whatsapp">
                <Icon name="fa6-brands:whatsapp" class="pr-1" />
              </span>
              <a
                href="https://wa.me/6285692816802"
                title=""
                class="text-gray-200 transition-all duration-200 hover:text-white focus:text-white font-bold"
              >
                0856-9281-6802
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="mt-20 md:mt-28 2xl:mt-32">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="sm:flex sm:items-center sm:justify-start sm:space-x-8">
            <ul class="flex items-center justify-start space-x-8">
              <li>
                <NuxtLink
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/profile.php?id=61560854992623"
                  external
                  title=""
                  aria-label="Facebook"
                  class="block text-white transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  target="_blank"
                >
                  <Icon name="fa6-brands:facebook-f" class="w-6 h-6" />
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  rel="noopener noreferrer"
                  href="https://tiktok.com/@gymblazeid"
                  external
                  title=""
                  aria-label="Tiktok"
                  class="block text-white transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  target="_blank"
                >
                  <Icon name="fa6-brands:tiktok" class="w-6 h-6" />
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  rel="noopener noreferrer"
                  href="https://instagram.com/gymblaze.id"
                  external
                  title=""
                  aria-label="Instagram"
                  class="block text-white transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  target="_blank"
                >
                  <Icon name="fa6-brands:instagram" class="w-6 h-6" />
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/@gymblaze"
                  external
                  title=""
                  aria-label="Youtube"
                  class="block text-white transition-all duration-200 hover:text-opacity-80 focus:text-opacity-80"
                  target="_blank"
                >
                  <Icon name="fa6-brands:youtube" class="w-6 h-6" />
                </NuxtLink>
              </li>
            </ul>

            <ul
              class="flex flex-wrap items-center justify-start mt-5 gap-x-8 sm:mt-0 gap-y-3"
            >
              <li>
                <NuxtLink
                  href="/privacy"
                  title=""
                  class="text-sm text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
                >
                  Privacy Policy
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  href="/terms"
                  title=""
                  class="text-sm text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
                >
                  Terms of Service
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  href="/monitor"
                  target="_blank"
                  title=""
                  external
                  class="text-sm text-gray-200 transition-all duration-200 hover:text-white focus:text-white"
                >
                  Status
                </NuxtLink>
              </li>
            </ul>
          </div>

          <p
            class="mt-6 text-sm text-gray-200 lg:mt-0 flex justify-start sm:justify-end gap-4 flex-wrap"
          >
            <span>Res {{ RES_VERSION }}</span>
            <span>
              © Copyright {{ new Date().getFullYear() }}, All Rights Reserved.
            </span>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const RES_VERSION = "v1.2.1";
</script>
